/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.section-title {
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: .5em;
  display: block;
}

.primary-text {
  color: #673ab7;
}

.mat-warn {
  color: #f44336;
}
